import React from 'react';
import Image from 'next/image';

type Props = {
  url: string;
  size?: string;
};

const PatientImage = ({ url, size }: Props) => {
  return (
    <div className={`patient-image ${size ? size : ''}`}>
      <Image
        layout="responsive"
        width={70}
        height={70}
        alt="Patient Image"
        src={url}
      />
    </div>
  );
};

export default PatientImage;

import * as texts from 'src/common/resources/texts';
import * as Sentry from '@sentry/react';
import { Alert } from 'react-bootstrap';
import FollowUpMessages from './FollowUpMessages';

const Notification = () => {
  return (
    <div className="notifications">
      <FollowUpMessages />
    </div>
  );
};
export default Sentry.withErrorBoundary(Notification, {
  fallback: <Alert variant="danger">{texts.UNEXPECTED_ERROR}</Alert>,
});

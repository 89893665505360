import { setCalendarSchedule } from '../store/CalendarSlice';
import { setDefaultService } from '../store/HealthCareServiceSlice';
import { AppDispatch } from 'src/common/store/index';

import { Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';

type ScheduleProps = {
  calendarSchedule: {
    start: Date | null | undefined;
    comment: string;
    service: string;
  };
};
const Schedule: React.FC<ScheduleProps> = ({ calendarSchedule }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div>
      <div className="mt-4" role="time">
        <div className="d-flex">
          <span className="label mb-2">Schedule</span>
        </div>
        <DatePicker
          dateFormat="Pp"
          placeholderText="Start Day"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          selected={calendarSchedule.start}
          onChange={(start) => {
            dispatch(setDefaultService({}));
            dispatch(setCalendarSchedule({ ...calendarSchedule, start }));
          }}
        />
        {new Date() > (calendarSchedule.start as Date) && (
          <Alert variant="light" className="branchLabel m-0 text-danger">
            <i className="eha-level4 text-danger me-2" />
            date is in the past
          </Alert>
        )}
      </div>
    </div>
  );
};
export default Schedule;

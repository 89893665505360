import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

interface DeleteButtonProps {
  name: string;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: (ev: any) => void;
  actionMessage: string;
}

const ClearButton = ({
  name,
  actionMessage,
  className,
  children,
  onClick,
}: DeleteButtonProps) => {
  const [showDialog, setActive] = useState(false);

  const [inputValue, setInputValue] = useState(undefined);

  const toggleDialog = () => {
    setActive(!showDialog);
    setInputValue(undefined);
  };

  const checkInput = (evt: any) => {
    setInputValue(evt.target.value.toUpperCase());
  };
  return (
    <div className={'delete-button ' + (className ? className : '')}>
      <div onClick={toggleDialog} className="w-100">
        {children}
      </div>
      {showDialog && (
        <div className="confirmation-dialog top-right">
          <div className="d-flex">
            <span>{actionMessage}</span>
            <i
              className="eha-close text-primary clickable"
              onClick={toggleDialog}></i>
          </div>
          <div className="d-grid gap-2 mt-2">
            <div className="form-group mb-1">
              <div className="input-wrapper">
                <input
                  autoFocus
                  type="text"
                  placeholder="type YES"
                  onChange={(evt) => checkInput(evt)}></input>
              </div>
            </div>
            <Button
              variant="danger"
              disabled={inputValue != 'YES'}
              onClick={onClick}>
              {name}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClearButton;

import { BaseAPI } from './baseApi';
import { IFhirPatientResource } from '@ambulatory/features/patient/interfaces/patient';

type SearchObj = {
  [key: string]: string;
};

type PatientsRes = {
  entry: IFhirPatientResource[];
};

type FilterType = SearchObj[];

export class Patient extends BaseAPI {
  headers = { 'content-type': 'application/fhir+json' };
  endpointUrl = `${process.env.NEXT_PUBLIC_DEMOGRAPHY_URL}/Patient`;

  async getPatientsCount() {
    const requestQuery = '?_summary=count';
    const count = await this.get(requestQuery);
    return count.total;
  }

  async getPatients(
    filters: FilterType,
    limit: number,
    offset: number,
    search?: string,
  ) {
    const searchObj: any = {
      active: 'true',
      _count: limit || '',
      _getpagesoffset: offset || '',
    };
    if (search) {
      searchObj['name'] = search;
    }
    filters.forEach((filter) => {
      const key = Object.keys(filter)[0];
      searchObj[key] = filter[key];
    });
    const data: PatientsRes = await this.search(searchObj);
    return data.entry;
  }

  async getPatient(identifier: string) {
    const searchObj = {
      active: 'true',
      identifier: identifier,
    };
    const data: PatientsRes = await this.search(searchObj);
    return data.entry?.[0]?.resource || null;
  }

  async searchPatient(searchObj: SearchObj) {
    const data: PatientsRes = await this.search(searchObj);
    return data.entry?.[0]?.resource || null;
  }
}

export class NextOfKin extends BaseAPI {
  headers = { 'content-type': 'application/fhir+json' };
  endpointUrl = `${process.env.NEXT_PUBLIC_DEMOGRAPHY_URL}/RelatedPerson`;

  async getNextOfKin(identifier: string) {
    const searchObject = { identifier: identifier };
    const data: PatientsRes = await this.search(searchObject);
    return data.entry || null;
  }
}

export class FhirOrganization extends BaseAPI {
  headers = { 'content-type': 'application/fhir+json' };
  endpointUrl = `${process.env.NEXT_PUBLIC_DEMOGRAPHY_URL}/Organization`;

  async getFhirOrganization(identifier: string) {
    const searchObj = {
      active: 'true',
      identifier: identifier,
    };
    const resp = await this.search(searchObj);
    return resp.entry?.[0];
  }
}

export class HeardAboutClinic extends BaseAPI {
  headers = { 'content-type': 'application/fhir+json' };
  endpointUrl = `${process.env.NEXT_PUBLIC_DEMOGRAPHY_URL}/ValueSet`;
}

import React from 'react';

type InputContainerProps = {
  children: React.ReactNode;
  label?: string;
  invert?: boolean;
  required?: boolean;
  name?: any;
  errors?: any;
  customError?: any;
  htmlFor?: string;
};

const InputContainer = ({
  name,
  label,
  invert,
  children,
  required,
  errors,
  customError,
  htmlFor,
}: InputContainerProps) => {
  return (
    <div
      className={`form-group col ${required ? 'required' : ''} ${
        invert ? 'form-group-invert' : ''
      } ${
        customError || (errors && (errors[name] || errors[customError]))
          ? 'error'
          : ''
      }`}>
      <label htmlFor={htmlFor} className="form-label">
        {label}
      </label>
      {errors && errors[name] && (
        <p className="form-text label text-danger">
          {errors[name].message ? errors[name].message : `Invalid ${label}`}
        </p>
      )}
      {errors && errors[customError] && (
        <p className="form-text label text-danger">
          {errors[customError].message
            ? errors[customError].message
            : `Invalid ${label}`}
        </p>
      )}
      {children}
    </div>
  );
};

export default InputContainer;

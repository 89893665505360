import * as texts from 'src/common/resources/texts';
import * as Sentry from '@sentry/react';
import { AppState } from 'src/common/store/index';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import FollowUpMessage from './FollowUpMessage';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { deactivateMessage } from '@appointment/features/appointment/services/appointmentService';
import { useSession } from 'next-auth/react';
import ClearButton from 'src/common/components/form/ClearButton';
import LoadingSpinner from 'src/common/components/loading/LoadingSpinner';
import { ISession } from 'src/common/interfaces/session';

export const Time = (props: { format: string }) => {
  const { format } = props;
  const [time, setTime] = useState(moment().format(format));
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format(format));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <div className="label">{time}</div>;
};
const FollowUpMessages = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const { data: session } = useSession() as unknown as ISession;
  const clinics = useSelector((state: AppState) => state.clinics);
  const { defaultBranch } = clinics;
  const { followUpMessages } = useSelector((state: AppState) => state.message);
  const sortedFollowUpMessages = [...followUpMessages]
    .filter((each) => (each.branch_id as number) === defaultBranch.branch_id)
    .sort((a, b) => {
      if (a.create_date !== undefined && b.create_date !== undefined) {
        return (
          new Date(b.create_date).valueOf() - new Date(a.create_date).valueOf()
        );
      }
      return 0;
    });

  const clearAllMessages = async () => {
    const deactivateParams = {
      is_active: false,
      write_username: session?.user?.email,
    };
    try {
      setIsSaving(true);
      setIsError(false);
      for (const eachMessage of followUpMessages) {
        const stringifyDeactivateMessage = JSON.stringify({
          ...eachMessage,
          type: 'remove_follow_up',
        });
        const deactivatedMessage = await deactivateMessage(
          eachMessage.id as string,
          deactivateParams,
        );
        if (deactivatedMessage) {
          window.ws.send(stringifyDeactivateMessage);
        }
      }
    } catch (error) {
      setIsError(true);
      Sentry.captureException(error);
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <div>
      <div className="text-box d-flex justify-content-between w-100 p-3">
        <Time format={'dddd, MMMM Do YYYY'} />
        {isSaving ? (
          <LoadingSpinner />
        ) : (
          <>
            <ClearButton
              actionMessage="Are you sure you want to clear these follow up messages "
              name="clear all"
              onClick={clearAllMessages}>
              <a href="#">
                <small>clear all</small>
              </a>
            </ClearButton>
          </>
        )}
      </div>
      {isError && (
        <div className="text-box small text-box-alert text-white m-auto">
          Error removing message, please contact admin
        </div>
      )}
      {followUpMessages?.length > 0 ? (
        <FollowUpMessage messages={sortedFollowUpMessages} />
      ) : (
        <div className="p-3 text-center w-100 label">No messages</div>
      )}
    </div>
  );
};
export default Sentry.withErrorBoundary(FollowUpMessages, {
  fallback: <Alert variant="danger">{texts.UNEXPECTED_ERROR}</Alert>,
});

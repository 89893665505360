import { Alert, Button, Modal } from 'react-bootstrap';
import * as texts from 'src/common/resources/texts';
import * as Sentry from '@sentry/react';
import Schedule from '@appointment/features/appointment/components/Schedule';
import Comment from '@appointment/features/appointment/components/Comment';
import FormButton from 'src/common/components/form/FormButton';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'src/common/store/index';
import {
  Booking,
  createBooking,
  fetchBookingByBoookingCode,
  fetchMessageById,
  fetchProvidersByServiceAndBranch,
  fetchServicesByBranch,
  fetchTicketById,
  updateMessageBookingId,
} from '@appointment/features/appointment/services/appointmentService';
import moment from 'moment';
import { useSession } from 'next-auth/react';
import { FollowUp } from 'src/common/store/MessageSlice';
import { useQuery } from 'react-query';
import { RoomTicket } from '@patientFlow/features/patient-flow/interfaces/ticket';
import { ServiceOptions } from '@appointment/features/appointment/store/HealthCareServiceSlice';
import { Patient } from 'src/common/services/demography';
import LoadingSpinner from 'src/common/components/loading/LoadingSpinner';
import PatientSummaryCard from '@ambulatory/features/patient/components/PatientSummaryCard';
import { IFhirPatient } from '@ambulatory/features/patient/interfaces/patient';
import { ProviderOptions } from '@appointment/features/appointment/interfaces/careProvider';
import Select, { SingleValue } from 'react-select';
import { BranchOptions } from '@patientFlow/features/patient-flow/store/ClinicsSlice';
import { ISession } from 'src/common/interfaces/session';

interface MyModal {
  show: boolean;
  onHide: () => void;
  messageId: string;
  updateFollowUpModalMessage?: (message: FollowUp) => void;
}
const BookAppointment = ({
  show,
  onHide,
  messageId,
  updateFollowUpModalMessage,
}: MyModal) => {
  const { data: session } = useSession() as unknown as ISession;
  const [isSaving, setIsSaving] = useState(false);
  const [saveAppointmentError, setSaveAppointmentError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const calendarFromStore = useSelector((state: AppState) => state.calendar);
  const { calendarSchedule } = calendarFromStore;

  const followUpMessageObject = {} as FollowUp;
  const ticketObject = {} as RoomTicket;
  const [followUpMessage, setFollowUpMessage] = useState(followUpMessageObject);
  const [ticket, setTicket] = useState(ticketObject);
  const [clientId, setClientId] = useState(0);
  const [services, setServices] = useState<ServiceOptions[]>([]);
  const [providers, setProviders] = useState<ProviderOptions[]>([]);
  const branchObject = {} as BranchOptions;
  const serviceObject = {} as SingleValue<ServiceOptions>;
  const providerObject = {} as SingleValue<ProviderOptions>;
  const [appointmentBranch, setAppointmentBranch] = useState(branchObject);
  const [appointmentService, setAppointmentService] = useState(serviceObject);
  const [appointmentProvider, setAppointmentProvider] =
    useState(providerObject);

  useQuery('follow-up-message', () => fetchMessageById(messageId), {
    onSuccess: (data) => {
      setFollowUpMessage(data?.data);
    },
    enabled: !!messageId,
    refetchOnWindowFocus: false,
  });
  useQuery(
    'ticket',
    () => fetchTicketById(parseInt(followUpMessage.resource_id)),
    {
      onSuccess: (data) => {
        setTicket(data?.data);
      },
      enabled: !!followUpMessage?.resource_id,
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    'booking',
    () => fetchBookingByBoookingCode(ticket.appointment_code),
    {
      onSuccess: (data) => {
        const booking = data?.data[0];
        setClientId(booking?.client_id);
        const patientBranch: BranchOptions = {
          branch_id: booking?.branch?.id,
          label: booking?.branch?.name,
          code: booking.branch?.code,
          value: booking.branch.name?.toLowerCase(),
        };
        const patientBookedService: ServiceOptions = {
          service_id: booking?.service?.id,
          label: booking?.service?.name,
          value: booking?.service?.name?.toLowerCase(),
        };
        const patientBookedProvider: ProviderOptions = {
          provider_id: booking.provider.id,
          label: booking.provider?.name,
          value: booking.provider?.name?.toLowerCase(),
        };
        setAppointmentBranch(patientBranch);
        setAppointmentProvider(patientBookedProvider);
        setAppointmentService(patientBookedService);
      },
      enabled: !!ticket?.appointment_code,
      refetchOnWindowFocus: false,
    },
  );

  const patientAPI = new Patient();
  const patientObj = {} as IFhirPatient;
  const [patient, setPatient] = useState(patientObj);
  const { isLoading: patientLoading } = useQuery(
    'patient-detail',
    async () =>
      await patientAPI.search({
        active: 'true',
        identifier: ticket.patient_number,
      }),
    {
      onSuccess: (data) => {
        const patientData = data;

        const patientDetail = patientData?.data?.entry[0];
        const gender = patientDetail?.resource?.gender;
        const dob = moment(patientDetail?.resource?.birthDate).format(
          'YYYY-MM-DD',
        );
        const patientInfo: IFhirPatient = {
          resourceType: patientDetail?.resource?.resourceType,
          identifier: patientDetail?.resource?.identifier,
          active: true,
          name: patientDetail?.resource?.name,
          gender: gender,
          birthDate: dob,
          telecom: patientDetail?.resource?.telecom,
          address: patientDetail?.resource?.address,
          maritalStatus: patientDetail?.resource.maritalStatus,
        };
        setPatient(patientInfo);
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
      enabled: !!ticket?.patient_number,
      refetchOnWindowFocus: false,
    },
  );
  const saveAppointment = async () => {
    try {
      setIsSaving(true);
      setSaveAppointmentError(false);
      setErrorMessage('');

      const bookingParams: Booking = {
        start_time: moment(calendarSchedule.start).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        service_id: parseInt(appointmentService?.service_id as string),
        comment: calendarSchedule.comment,
        provider_id: parseInt(appointmentProvider?.provider_id as string),
        client_id: clientId,
        branch_id: appointmentBranch.branch_id,
        create_username: session?.user?.email as string,
      };

      const createdBooking = await createBooking(bookingParams, true); // follow up booking
      const booking_id = createdBooking?.data?.id;
      const updateMessageParams = {
        booking_id,
        write_username: session?.user?.email,
      };
      const updatedMessage = await updateMessageBookingId(
        messageId,
        updateMessageParams,
      );
      // This will only be called if we are booking this from transition modal
      if (updateFollowUpModalMessage) {
        updateFollowUpModalMessage({
          ...updatedMessage?.data,
        });
      }
      const stringifyUpdatedMessage = JSON.stringify({
        ...updatedMessage?.data,
        type: 'update_follow_up',
      });

      if (updatedMessage) {
        window.ws.send(stringifyUpdatedMessage);
        setIsSaving(false);
        onHide();
      }
    } catch (error) {
      const customError = error as {
        response: { data: { detail: string | { msg: string }[] } };
      };
      setIsSaving(false);
      setSaveAppointmentError(true);
      const returnedError = Array.isArray(customError.response.data.detail)
        ? customError.response.data.detail[0]?.msg
        : customError.response.data.detail;
      setErrorMessage(returnedError);
      Sentry.captureException(error);
    }
  };

  useQuery(
    'services',
    () => fetchServicesByBranch(appointmentBranch?.branch_id),
    {
      onError: (error) => Sentry.captureException(error),
      onSuccess: (data) => {
        const serviceList = data?.data.map(
          (eachService: { id: string; name: string }) => {
            const service = {} as ServiceOptions;
            service.service_id = eachService.id;
            service.label = eachService.name;
            service.value = eachService.name.toLowerCase();
            return service;
          },
        );
        setServices(serviceList);
      },
      enabled: !!appointmentBranch?.branch_id,
      refetchOnWindowFocus: false,
    },
  );
  useQuery(
    ['providers', appointmentService?.service_id],
    () =>
      fetchProvidersByServiceAndBranch(
        appointmentBranch?.branch_id,
        appointmentService?.service_id as string,
      ),
    {
      enabled: !!appointmentService?.service_id,

      onError: (error) => Sentry.captureException(error),
      onSuccess: (careProviders) => {
        const providerList = careProviders.data.map(
          (eachProvider: { id: string; name: string }) => {
            const allCareProvidersDropDown = {} as ProviderOptions;
            allCareProvidersDropDown.provider_id = eachProvider.id;
            allCareProvidersDropDown.label = eachProvider.name;
            allCareProvidersDropDown.value = eachProvider.name?.toLowerCase();
            return allCareProvidersDropDown;
          },
        );
        setProviders(providerList);
      },
    },
  );

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>New Follow Up Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="label mb-2">Patient</div>
            {patientLoading ? (
              <LoadingSpinner />
            ) : (
              patient?.gender && <PatientSummaryCard patient={patient} />
            )}
            <div className="mt-4">
              <div className="label mb-2">requested Service</div>
              <div className="text-box">{calendarSchedule.service}</div>
            </div>
            <Comment />
          </div>
          <div className="col-md-6">
            <label htmlFor="service" className="label mb-2">
              Branch
            </label>
            {appointmentBranch?.branch_id ? (
              <Select
                instanceId="Clinic Details"
                name="branches"
                inputId="branches"
                value={appointmentBranch}
                className="select select-dark"
                classNamePrefix="select"
              />
            ) : (
              <LoadingSpinner />
            )}

            <div>
              <form data-testid="service" className="mt-4" role="service">
                <label htmlFor="service" className="label mb-2">
                  Service
                </label>
                {appointmentService?.service_id ? (
                  <Select
                    value={appointmentService}
                    name="service"
                    inputId="service"
                    options={services}
                    onChange={(option) => {
                      setAppointmentService(option);
                      setAppointmentProvider(providerObject);
                    }}
                    className="select select-dark"
                    classNamePrefix="select"
                  />
                ) : (
                  <LoadingSpinner />
                )}
              </form>
              <form
                data-testid="provider"
                className="mt-4"
                role="care-provider">
                <label htmlFor="provider" className="label mb-2">
                  Care Provider
                </label>
                {appointmentService?.service_id ? (
                  <>
                    <Select
                      value={appointmentProvider}
                      name="provider"
                      inputId="provider"
                      options={providers}
                      onChange={(option) => {
                        setAppointmentProvider(option);
                      }}
                      className="select select-dark"
                      classNamePrefix="select"
                    />
                    {!appointmentProvider?.provider_id && (
                      <Alert
                        variant="light"
                        className="branchLabel m-0 text-danger">
                        <i className="eha-level4 text-danger me-2" />
                        please select a provider
                      </Alert>
                    )}
                  </>
                ) : (
                  <LoadingSpinner />
                )}
              </form>
            </div>
            <Schedule calendarSchedule={calendarSchedule} />
          </div>
        </div>
        {saveAppointmentError && (
          <>
            {errorMessage ? (
              <Alert variant="light" className="label m-0 text-danger">
                <i className="eha-level4 text-danger me-2" />
                {errorMessage}
              </Alert>
            ) : (
              <Alert variant="light" className="label m-0 text-danger">
                <i className="eha-level4 text-danger me-2" />
                error while saving appointment
              </Alert>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>Cancel</Button>
        <FormButton
          disabled={appointmentProvider?.provider_id ? false : true}
          loading={isSaving}
          onClick={saveAppointment}>
          Save Appointment
        </FormButton>
      </Modal.Footer>
    </Modal>
  );
};
export default Sentry.withErrorBoundary(BookAppointment, {
  fallback: <Alert variant="danger">{texts.UNEXPECTED_ERROR}</Alert>,
});

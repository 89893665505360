import React from 'react';
import PatientImage from './PatientImage';
import { IFhirPatient } from '../interfaces/patient';
import { reformatDob } from 'src/common/utils/dobUtils';
import { genderSwitch } from 'src/common/utils/iconUtils';

type PatientSummaryCardType = {
  patient: IFhirPatient;
};

const PatientSummaryCard: React.FC<PatientSummaryCardType> = ({ patient }) => {
  return (
    <div className="patient-card">
      <div className="d-flex align-items-center">
        <PatientImage size="xs" url="/img/patient-placeholder.jpg" />
        <span className="mx-3">
          {patient.name[0].text}
          <span className="circle circle-xs ms-2">
            <i className={genderSwitch(patient.gender)}></i>
          </span>
        </span>
      </div>
      <div className="label-group mx-3">
        <span className="label">ID</span>
        <span>{patient.identifier[0].value}</span>
        {patient.birthDate && (
          <>
            <span className="label">dob</span>
            <span>{reformatDob(patient.birthDate)}</span>
          </>
        )}
        {patient.telecom[0].value && (
          <>
            <span className="label">phone</span>
            <span>{patient.telecom[0].value}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default PatientSummaryCard;

import InputContainer from 'src/common/components/form/InputContainer';
import { setCalendarSchedule } from '../store/CalendarSlice';
import { AppDispatch, AppState } from 'src/common/store/index';
import { useDispatch, useSelector } from 'react-redux';

const Comment = () => {
  const dispatch = useDispatch<AppDispatch>();
  const calendarFromStore = useSelector((state: AppState) => state.calendar);
  const { calendarSchedule } = calendarFromStore;
  return (
    <div>
      <div className="mt-4" role="comment">
        <InputContainer name="comment" label="Comment">
          <textarea
            value={calendarSchedule.comment}
            onChange={(e) =>
              dispatch(
                setCalendarSchedule({
                  ...calendarSchedule,
                  comment: e.target.value,
                }),
              )
            }
            rows={2}></textarea>
        </InputContainer>
      </div>
    </div>
  );
};
export default Comment;

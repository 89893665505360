import { deactivateMessage } from '@appointment/features/appointment/services/appointmentService';
import { FollowUp, removeFollowUpMessage } from 'src/common/store/MessageSlice';
import moment from 'moment';
import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { useSession } from 'next-auth/react';
import * as texts from 'src/common/resources/texts';
import * as Sentry from '@sentry/react';
import LoadingSpinner from 'src/common/components/loading/LoadingSpinner';
import { AppDispatch, AppState } from 'src/common/store/index';
import { useDispatch, useSelector } from 'react-redux';
import ClearButton from 'src/common/components/form/ClearButton';
import { setCalendarSchedule } from '@appointment/features/appointment/store/CalendarSlice';
import BookAppointment from './BookAppointment';
import { getUserLocalDateTime } from 'src/common/utils/utils';
import { ISession } from 'src/common/interfaces/session';

interface FollowUpMessageProps {
  messages: FollowUp[];
  updateFollowUpModalMessage?: (message: FollowUp) => void;
}

const FollowUpMessage = ({
  messages,
  updateFollowUpModalMessage,
}: FollowUpMessageProps) => {
  const { data: session } = useSession() as unknown as ISession;
  const dispatch = useDispatch<AppDispatch>();

  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const [messageIdToDelete, setMessageIdToDelete] = useState('');

  const { followUpMessageRemovalState } = useSelector(
    (state: AppState) => state.message,
  );

  const handleDeactivateMessage = async (eachMessage: FollowUp) => {
    const deactivateParams = {
      is_active: false,
      write_username: session?.user?.email as string,
    };
    try {
      setMessageIdToDelete(eachMessage.id as string);
      setIsSaving(true);
      setIsError(false);
      const deactivatedMessage = await deactivateMessage(
        eachMessage.id as string,
        deactivateParams,
      );
      deactivatedMessage && dispatch(removeFollowUpMessage(eachMessage));
    } catch (error) {
      setIsError(true);
      Sentry.captureException(error);
    } finally {
      setIsSaving(false);
    }
  };
  const bookAppointment = (eachMessage: FollowUp) => {
    setFollowUpAppointmentModal(true);
    setMessageId(eachMessage.id as string);
    const followUpDate = eachMessage?.followup_date;
    const scheduleTime = new Date(moment(followUpDate).format());
    const comment = eachMessage?.comment;
    const service = eachMessage?.subject;
    const scheduleParams = {
      comment,
      service,
      start: scheduleTime,
    };
    dispatch(setCalendarSchedule(scheduleParams));
  };
  const [followUpAppointmentModal, setFollowUpAppointmentModal] =
    useState(false);
  const [messageId, setMessageId] = useState('');
  const closeFollowUpAppointmentModal = () => {
    setFollowUpAppointmentModal(false);
    setMessageId('');
  };

  return (
    <>
      {followUpAppointmentModal && (
        <BookAppointment
          show={followUpAppointmentModal}
          onHide={closeFollowUpAppointmentModal}
          messageId={messageId}
          updateFollowUpModalMessage={updateFollowUpModalMessage}
        />
      )}
      {messages.map((eachMessage) => (
        <div key={eachMessage.id} className="message p-3">
          <div className="d-flex">
            <div className="label">{eachMessage.message_from}</div>
            <div className="label ms-auto">
              {getUserLocalDateTime(
                eachMessage?.create_date as string,
                'MMM DD yyyy, h:mma',
              )}
            </div>
            {isSaving && eachMessage.id === messageIdToDelete ? (
              <LoadingSpinner />
            ) : (
              <>
                {followUpMessageRemovalState && (
                  <ClearButton
                    actionMessage="Are you sure you want to clear this follow up"
                    onClick={() => handleDeactivateMessage(eachMessage)}
                    name="clear">
                    <a href="#" className="ms-3">
                      <i className="eha-close"></i>
                    </a>
                  </ClearButton>
                )}
              </>
            )}
          </div>
          <div className="my-2">
            <b>Follow up Appointment required for {eachMessage.message_to}</b>
          </div>
          <div
            className={
              'follow-up-note text-box w-100 rounded p-3 pt-2 ' +
              (eachMessage.booking_id ? 'bg-light' : '')
            }>
            <div className="d-flex align-items-start mb-2">
              <i className="eha-appointment fs-4"></i>
              <div className="separated-text ms-2">
                <span>{eachMessage.subject}</span>
                <span>{eachMessage.message_to}</span>
                <span>
                  {moment(eachMessage.followup_date).format(
                    'MMMM Do YYYY h:mma',
                  )}
                </span>
                {eachMessage.comment && (
                  <div className="mt-2 text-body-light">
                    <span>{eachMessage.comment}</span>
                  </div>
                )}
              </div>
            </div>
            {!eachMessage.booking_id ? (
              <Button size="sm" onClick={() => bookAppointment(eachMessage)}>
                Book Appointment
              </Button>
            ) : (
              <div>
                <Badge bg="success">Booked</Badge>
                <i className="eha-check-circle text-success fs-1"></i>
              </div>
            )}
          </div>
          {isError && (
            <div className="text-box small text-box-alert text-white m-auto">
              Error removing message, please contact admin
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default Sentry.withErrorBoundary(FollowUpMessage, {
  fallback: <Alert variant="danger">{texts.UNEXPECTED_ERROR}</Alert>,
});
